/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #494E54; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #878c8f; 
  border-radius: 10px;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.text-bold{
  font-weight: bold !important;
}

.Map_Pin_Img_Text{
  display: none;
}

.Map_Pin_Div{
  margin-top: -32px;
}

.Map_Pin_Div:hover .Map_Pin_Img_Text{
  /* display: block;
  color: #fff;
  width:max-content; */
}
.Map_Pin_Div:hover{
  /* margin-top: -66.26px; */
}
/* .Map_Pin_Img_Text:hover{
  display: block;
  color: #fff;
  width: fit-content;
} */
.select_Box{
  cursor: pointer;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.icon-container {
  perspective: 1000px;
  cursor: pointer;
  display: inline-block;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flipped .icon {
  transform: rotateY(180deg);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
