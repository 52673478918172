::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: gray;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #000;
  border-radius: 10px;
}

.Dashboard_Box {
  background-color: #f5f5f5;
}

.Dashboard_Navigation_Card {
  min-height: 20px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  margin: 15px 0px;
  cursor: pointer;
}

.Dashboard_Navigation_Card_Sm {
  min-height: 20px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  margin: 0px;
}

.Dashboard_Navigation_Card_Box {
  height: 90vh;
  overflow: auto;
}

.Dashboard_V2_Navigation_Card_Box {
  height: 60vh;
  overflow: auto;
}

.Dashboard_Navigation_Setting_Box {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  height: auto;
}

.Dashboard_Navigation_Setting_Box_Big_Screen {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  justify-content: space-between;
  height: auto;
}

.Dashboard_Navigation_Button_Box {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.Dashboard_Navigation_Button_Box_Big_Screen {
  display: flex;
  justify-content: space-around;
}

.Dashboard_Navigation_Button {
  color: #fff;
  background: #252a2d;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #686c70;
}

.Dashboard_Navigation_close_Button {
  color: #fff;
  background: #252a2d;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #686c70;
  font-size: 16px !important;
}

.Dashboard_Navigation_Page_Text {
  color: #686c70;
  font-size: 18px;
  font-weight: 500 !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 30px;
}

.Dashboard_Navigation_Page_Text_Highlights {
  font-weight: 500 !important;
  color: #fff;
  margin: 0 10px;
}

.Dashboard_Navigation_Card_text {
  color: #fff;
  font-size: 16px;
}

.Dashboard_Option_Button {
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.Dashboard_Option_close_Button {
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  justify-content: center;
}

.Dashboard_Asset_Card {
  background: #40514e;
  display: flex;
  flex-direction: row;
  justify-content: start;
  min-height: 75px;
  border-radius: 5px;
  margin: 0 7px 20px 7px;
  box-shadow: 0px 0px 15px -5px #000;
  min-height: 110px;
}


.Dashboard_Asset_Card_DR {
  background: #40514e;
  display: flex;
  flex-direction: row;
  justify-content: start;
  min-height: 75px;
  border-radius: 5px;
  margin: 0 7px 20px 7px;
  box-shadow: 0px 0px 15px -5px #000;
  min-height: 180px;
}

.text-center {
  text-align: center;
}

.Dashboard_Asset_Card_Text {
  text-align: start;
  color: #fff;
}


.Dashboard_Asset_Card_Text_DR_Center {
  text-align: center;
  color: #fff;
}


.Dashboard_Asset_Card_Text_DR {
  text-align: center !important;
  margin-bottom: 10px;
  text-align: start;
  color: #fff;
}

.text-black {
  color: #000;
}

.Bold_text {
  font-weight: 800;
}

.Dashboard_Asset_Heading {
  color: #000;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Dashboard_Right_Navigation_Bar {
  background: #22272b;
  padding: 10px !important;
  position: sticky;
  top: 0;
  max-height: 99vh;
  display: grid;
  overflow: auto;
  min-height: 100vh;
}

.Dashboard_Big_Screen {
  display: none;
}

.Dashboard_Sm_Screen {
  display: block;
}

.MuiAccordionSummary-content {
  margin: 0px !important;
  display: block !important;
}

.DashboardComponent_Footer {
  padding: 10px;
  background: #22272b;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: fixed;
  width: -webkit-fill-available;
  bottom: 0;
  height: 40px;
}

.Dashboard_Navigation_Back_Btn {
  text-align: start;
  border: 1px solid #fff;
  width: min-content;
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  width: 20px;
  height: 20px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Dashboard_Navigation_Top_Btn {
  padding: 5px;
  width: 20px;
  height: 20px;
  margin-bottom: 15px;
}

.Dashboard_Asset_Card_Test_Box {
  width: 70% !important;
}

.Dashboard_Asset_Card_Text_Highlight {
  border-radius: 0px 5px 5px 0px;
  padding: 0 7px;
}

.Dashboard_Navigation_Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 10px;
}

.Dashboard_Navigation_Header_Text {
  color: #fff;
}

.oneLineCSS {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dashboard_Box {
  height: calc(100vh - 60px);
}

.dashboard_Box_ON_RENT {
  overflow: auto;
  height: calc(100vh - 60px);
}

.logo-light {
  width: 100%;
  margin: 10px 5px;
}

.select_Box {
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  background-color: #0000;
}

.select_option {
  background: #000;
  color: #fff;
}


select {
  font-size-adjust: 0.3;
}

.mb-50 {
  margin-bottom: 50px;
}

.cursor_pointer {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .Dashboard_Big_Screen {
    display: block;
  }

  .Dashboard_Sm_Screen {
    display: none;
  }

  .Dashboard_Navigation_close_Button {
    height: 40px;
    width: 40px;
  }

  .Dashboard_Navigation_Button {
    height: 40px;
    width: 40px;
    margin: 0 5px;
    margin: auto;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



.contractImgInfo_img {
  height: 400px
}

@media only screen and (max-width: 2000px) {

  .contractImgInfo_img {
    height: 270px
  }

}


@media only screen and (max-width: 1440px) {

  .contractImgInfo_img {
    height: 250px
  }

}


/* The icon */
.help-tip {
  position: absolute;
  z-index: 1;
  top: -20%;
  left: 50%;
  transform: translate(-12%, -89%);
  margin: auto;
  text-align: center;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 42px;
  cursor: default;
}

.help-tip:before {
  font-family: sans-serif;
  font-weight: normal;
  color: #444;
}

/* The tooltip */
.help-tip p {
  display: block;
  transform-origin: 100% 0%;
  -webkit-animation: fadeIn 0.3s ease;
  animation: fadeIn 0.3s ease;
  font-family: sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  background-color: #FFFFFF;
  padding: 12px 16px;
  width: 300px;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, calc(-100% - 20px));
  border-radius: 5px;
  /* 	border: 1px solid #E0E0E0; */
  box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.1);
  color: #37393D;
  font-size: 12px;
  line-height: 18px;
  z-index: 99;
}

/* The pointer of the tooltip */
.help-tip p:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #FFFFFF;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: rotate(180deg);
}

/* Prevents the tooltip from being hidden */
.help-tip p:after {
  width: 10px;
  height: 40px;
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
}

.custom_pop_up {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.custom_pop_up_head_1 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: #344050;
  margin-bottom: 0.5rem;
  text-align: start;
}

.custom_pop_up_head_2 {
  font-size: .83333rem;
  font-weight: 600;
  line-height: 1.2;
  color: #344050;
  margin-bottom: 0.5rem;
}

.custom_pop_up_head_3 {
  margin-top: 0;
  margin-bottom: 3px;
  color: #5e6e82;
  text-align: left;
}

.custom_pop_up_close_icon_box {
  text-align: end;
  width: 100%;
}

.custom_pop_up_close_icon {
  width: 10px;
  cursor: pointer;
}

/* CSS animation */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}