.Login_Page_Box {
  height: 100vh;
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login_Box {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #fff;
}
