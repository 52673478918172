.Home_Bg{
    background: #fff;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
}

.Home_Card{
    cursor: pointer;
    display: flex;
    justify-content: center;
    border: 1px solid #000;
}

.Home_Card:hover{
    box-shadow: 0px 0px 13px #000 !important;
    /* transform: translateY(-5px); */
}

.Home_Box:hover{
    transform: translateY(-5px);

}

.Home_Card_Logo{
    transform: translateY(-50px);
    position: absolute;
}